<template>
  <section v-if="!productStore.loading" class="info">
    <h6 class="alt">{{ product.vendor || product.seller_name || 'In-House' }}</h6>
    <h4>{{ product.name }}</h4>
    <!-- <h5 v-if="variant.title">{{ variant.title }}</h5> -->
    <small class="sku" v-if="variant.sku">
      <strong>{{ $t('ui.sku') }}</strong> {{ variant.sku }}
    </small>
    <div class="money">
      <span>{{ formatMoney({ amount: variant.price }) }}</span>
      <div v-if="shippingFeeIncluded !== null" class="shipping-fee">
        <small v-if="shippingFeeIncluded" class="shipping-included">
          {{ $t('options.shipping_fee.included') }}
        </small>
        <small v-else class="shipping-not-included">
          {{ $t('options.shipping_fee.not_included') }}
        </small>
      </div>
    </div>
    <pre class="desc">{{ product.description }}</pre>
    <ShopProductOptions v-if="product.options" />
    <FormNumberInput
      title="forms.label.quantity"
      type="number"
      v-model.number="quantity"
      :quantity_rules="product.quantity_rules"
    />
    <div v-if="product.quantity_rules" class="quantity-rules">
      <small v-if="product.quantity_rules.increment" class="increment-info">
        {{ $t('products.increment_info', { increment: product.quantity_rules.increment }) }}
      </small>
      <small v-if="product.quantity_rules.min > product.quantity_rules.increment" class="min-info">
        {{ $t('products.min_info', { min: product.quantity_rules.min }) }}
      </small>
      <small v-if="product.quantity_rules.max > 0" class="max-info">
        {{ $t('products.max_info', { max: product.quantity_rules.max }) }}
      </small>
    </div>
    <div
      class="btn"
      :class="{ disabled: !canAddToCart }"
      :disabled="!canAddToCart"
      @click="addToCart"
    >
      <IconLibrary name="order" size="sm" />
      <span>{{ buttonText }}</span>
    </div>
    <CartPopup :show="showCartPopup" @update:show="showCartPopup = $event" />
  </section>
</template>

<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useProductStore } from '../../stores/products.js';
import { useCartStore } from '../../stores/cart.js';
import { useUserStore } from '../../stores/user.js';
import { formatMoney } from '../../libs/utils.js';
import { kyWithAuth } from '../../libs/ky.js';
import { useI18n } from 'vue-i18n';
import FormNumberInput from '../Shared/Forms/FormNumberInput.vue';
import ShopProductOptions from './ShopProductOptions.vue';
import IconLibrary from '../Shared/IconLibrary.vue';
import CartPopup from '../Shared/CartPopup.vue';

const { t } = useI18n();
const productStore = useProductStore();
const cartStore = useCartStore();
const userStore = useUserStore();

defineProps({ product: { type: Object } });
const { variant, product } = storeToRefs(productStore);
const quantity = ref(0);
const shippingFeeIncluded = ref(null);
const showCartPopup = ref(false); // Contrôler l'affichage du popup

const fetchSellerDetails = async (sellerId) => {
  try {
    const { user: seller } = await kyWithAuth.get(`users/${sellerId}`).json();
    const matchedDirector = seller.directors.find(
      (director) => director?.user_id?.toString() === userStore.user?._id?.toString()
    );
    shippingFeeIncluded.value = matchedDirector.shipping_fee_included;
  } catch (e) {
    console.error(e);
  }
};

const addToCart = () => {
  const selectedVariant = variant?.value;
  const selectedProduct = product?.value;
  const selectedOptions =
    selectedVariant?.options?.map((option) => `${option.name} / ${option.value}`) || [];
  if (canAddToCart.value) {
    cartStore.add({
      productId: selectedProduct._id,
      variantId: selectedVariant?._id,
      sellerId: selectedProduct.seller_id,
      quantity: quantity.value,
      name: selectedProduct.name,
      options: selectedOptions.join(' - '),
      sku: selectedVariant?.sku,
      price: selectedVariant?.price,
      image: selectedProduct.images?.[0],
      ...selectedProduct.quantity_rules
    });
    showCartPopup.value = true;
  }
};

const canAddToCart = computed(() => {
  const minQuantity = product.value.quantity_rules?.min || 1;
  const currentQuantity = Number(quantity.value);
  const stockQuantity = variant.value?.quantity || 0;

  // Si backorder est autorisé, on peut toujours ajouter au panier
  if (variant.value?.allow_backorder) {
    return true;
  }

  // Sinon on vérifie :
  // 1. Que la quantité est >= au minimum requis
  // 2. Que la quantité demandée ne dépasse pas le stock disponible
  return currentQuantity >= minQuantity && currentQuantity <= stockQuantity;
});

const buttonText = computed(() => {
  if (!canAddToCart.value) {
    // Si on a du stock mais pas assez pour la quantité demandée
    if (variant.value?.quantity > 0 && quantity.value > variant.value.quantity) {
      return t('forms.button.insufficient_stock');
    }
    return t('forms.button.out_of_stock');
  }
  return t('forms.button.add_to_cart');
});

// Watch les règles de quantité pour initialiser correctement
watch(
  () => product.value.quantity_rules,
  (rules) => {
    console.log('Rules updated:', rules); // Debug

    if (rules?.increment) {
      const increment = Number(rules.increment);
      const min = rules.min ? Number(rules.min) : increment;
      quantity.value = Math.max(min, increment);
      console.log('Setting quantity to:', quantity.value); // Debug
    } else if (rules?.min) {
      quantity.value = Number(rules.min);
    } else {
      quantity.value = 1;
    }
  },
  { immediate: true } // Pour exécuter immédiatement et à chaque changement
);

onMounted(async () => {
  if (product.value.seller_id) {
    await fetchSellerDetails(product.value.seller_id); // Fetch seller details on mount
  }
});

watch(quantity, (newQty) => {
  if (newQty === '') quantity.value = 0;
});
</script>

<style lang="scss" scoped>
h4 {
  margin-top: 0;
}
h5 {
  color: $brand-light-purple;
  font-weight: $font-weight-light;
  font-style: italic;
  font-size: 105%;
}
.info {
  position: relative;
}
.alt {
  text-transform: uppercase;
  color: $brand-light-purple;
  font-size: 80%;
  margin-bottom: 10px;
}
.sku {
  color: $brand-light-purple;
  font-size: 80%;
}
.money {
  font-size: 125%;
  margin: 15px 0 35px;
}
.btn {
  margin-top: 35px;
  justify-content: center;
  background-color: $brand-dark-purple;

  &.disabled {
    opacity: 0.25 !important;
    pointer-events: none;
  }
}
:deep(input[type='number']) {
  background-color: $white;
  border: 2px solid rgba($brand-blue, 0.3);
  color: $tuatara;
  transition: border 200ms ease, color 200ms ease, transform 200ms ease-in-out;
  font-size: 16px;
}
.increment-info {
  display: block;
  color: $brand-light-purple;
  font-size: 85%;
  margin-top: 5px;
  font-style: italic;
}
.quantity-rules {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 5px;

  small {
    color: $brand-light-purple;
    font-size: 85%;
    font-style: italic;
  }
}
</style>
