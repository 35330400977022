<template>
  <div class="products">
    <h6>{{ $t('catalogs.products.title') }}</h6>
    <ProductSearch class="products-search" />

    <article
      class="product"
      :class="{ included: product.included }"
      v-for="product in allProducts"
      :key="product._id"
    >
      <FormCheckbox />
      <img class="image" :src="product?.images?.[0]" :alt="product.name" />
      <div class="name">
        {{ product.name }}<br />
        <span v-if="product.variants.length > 1">
          <a href="#">
            <small>{{ product.variants.length }} variants</small>
          </a>
        </span>
      </div>
      <div class="status" :class="statusColor(product.status)">{{ product.status }}</div>
      <div v-show="product.included" class="price">
        <FormPriceInput
          title="forms.label.price"
          :label="false"
          v-model.number="product.custom_price"
          @input="
            () => debouncedUpdateProductSelection({ productId: product._id, included: false })
          "
        />
        <div v-if="product.variants.length > 1" class="warning">
          Will be applied to {{ product.variants.length }} variants
        </div>
      </div>
      <div class="rules">
        <div v-show="product.included" class="btn btn-ghost" @click="openModal(product._id)">
          <IconLibrary name="add-product" size="sm" />Add rules
        </div>
      </div>
      <div class="actions">
        <div
          v-if="!product.included"
          class="btn"
          @click="updateProductSelection({ productId: product._id, included: false })"
        >
          <IconLibrary name="add-product" size="sm" />Ajouter
        </div>
        <div
          v-else
          class="btn btn-ghost btn-delete"
          @click="updateProductSelection({ productId: product._id, included: true })"
        >
          <IconLibrary name="delete" size="sm" />Retirer
        </div>
      </div>
    </article>
    <Pagination
      :currentPage="productStore.currentPage"
      :hasNextPage="productStore.hasNextPage"
      :pageSize="productStore.pageSize"
      @update:page="changePage"
    />
    <Modal ref="modal">
      <template #title>{{ $t('modal.catalogs.rules.title') }}</template>
      <form id="submit-catalog" @submit.prevent="submitForm">
        <FormNumberInput
          title="Increment"
          v-model.number="increment"
          :quantity_rules="{ min: 1 }"
          @input="handleIncrementChange"
        />
        <FormNumberInput
          title="Min"
          v-model.number="min"
          :disabled="!increment"
          :quantity_rules="{
            min: increment,
            increment: increment
          }"
          @input="handleMinChange"
        />
        <FormNumberInput
          title="Max"
          v-model.number="max"
          :disabled="!increment"
          :quantity_rules="{
            min: min + increment,
            increment: increment
          }"
          @input="handleMaxChange"
        />
        <input type="submit" hidden />
      </form>
      <template #footer>
        <div class="btn btn-ghost" @click="handleCancel">Cancel</div>
        <button type="submit" form="submit-catalog" class="btn" @click="submitForm">
          {{ $t('forms.button.submit') }}
        </button>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import FormCheckbox from '../Shared/Forms/FormCheckbox.vue';
import Modal from '../Shared/Modal.vue';
import FormPriceInput from '../Shared/Forms/FormPriceInput.vue';
import IconLibrary from '../Shared/IconLibrary.vue';
import Pagination from '../Shared/Pagination.vue';
import ProductSearch from '../Products/ProductSearch.vue';
import { useProductStore } from '../../stores/products.js';
import { storeToRefs } from 'pinia';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import FormNumberInput from '../Shared/Forms/FormNumberInput.vue';
const productStore = useProductStore();
const { getSortedProducts, currentPage, pageSize } = storeToRefs(productStore);

const modal = ref();
const increment = ref(null);
const min = ref(null);
const max = ref(null);
const currentProductId = ref();
const globalSelectedProducts = ref(new Map());

const allProducts = computed(() => {
  return getSortedProducts.value.map((product) => {
    const existingProduct = globalSelectedProducts.value.get(product._id);
    if (existingProduct) {
      return {
        ...product,
        ...existingProduct,
        product_id: product._id,
        included: existingProduct.included
      };
    }
    return {
      ...product,
      included: false,
      product_id: product._id,
      custom_price: null,
      quantity_rules: { increment: null, min: null, max: null }
    };
  });
});

function changePage(page) {
  productStore.currentPage = page;
  productStore.fetchProducts({ page, pageSize: productStore.pageSize });
}

const debounce = (func, delay) => {
  let debounceTimer;
  return (...args) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func(...args), delay);
  };
};

const { modelValue } = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['update:modelValue']);

const emitUpdatedModelValue = () => {
  emit('update:modelValue', Array.from(globalSelectedProducts.value.values()));
};

function initializeSelectedProducts() {
  // Retrieve full product details and merge with modelValue
  modelValue.forEach((productInfo) => {
    const fullProduct = getSortedProducts.value.find((p) => p._id === productInfo.product_id) || {};
    globalSelectedProducts.value.set(productInfo.product_id, {
      ...fullProduct,
      ...productInfo,
      included: true
    });
  });
}

onMounted(() => {
  try {
    if (!productStore.products.length) {
      productStore.fetchProducts({ page: currentPage.value, pageSize: pageSize.value }).then(() => {
        initializeSelectedProducts();
      });
    } else {
      initializeSelectedProducts();
    }
  } catch (e) {
    console.error('error while fetching products', e);
  }
});

onUnmounted(() => {
  productStore.page = 1;
});

const updateQuantityRules = (productId) => {
  const existingProduct = globalSelectedProducts.value.get(productId);
  if (existingProduct) {
    const updatedProduct = {
      ...existingProduct,
      quantity_rules: {
        increment: increment.value || existingProduct.quantity_rules.increment,
        min: min.value || existingProduct.quantity_rules.min,
        max: max.value || existingProduct.quantity_rules.max
      }
    };
    // Set the updated product back into the global map
    globalSelectedProducts.value.set(productId, updatedProduct);
    emitUpdatedModelValue();

    // // Trigger reactivity by replacing the Map (if necessary)
    // globalSelectedProducts.value = new Map(globalSelectedProducts.value);
  }
};

const updateProductSelection = ({ productId, included = false }) => {
  const product = allProducts.value.find((d) => d._id === productId);
  if (product) {
    product.included = !included;
    product.product_id = productId;
    if (!included) {
      globalSelectedProducts.value.set(productId, { ...product });
    } else {
      globalSelectedProducts.value.delete(productId);
    }
    emitUpdatedModelValue();
    // Trigger reactivity by replacing the Map
    globalSelectedProducts.value = new Map(globalSelectedProducts.value);
  }
};
const debouncedUpdateProductSelection = debounce(updateProductSelection, 300);

const statusColor = (status) => (status === 'active' ? 'green' : 'yellow');

const setupModalForProduct = (productId) => {
  currentProductId.value = productId;
  const product = allProducts.value.find((p) => p._id === productId);
  if (product) {
    increment.value = product.quantity_rules?.increment ?? null;
    min.value = product.quantity_rules?.min ?? null;
    max.value = product.quantity_rules?.max ?? null;
  }
};

const openModal = (productId) => {
  setupModalForProduct(productId);
  modal.value.open();
};

const closeModal = () => {
  resetModalFields();
  modal.value.close();
};

const resetModalFields = () => {
  increment.value = null;
  min.value = null;
  max.value = null;
  currentProductId.value = null;
};

const submitForm = () => {
  updateQuantityRules(currentProductId.value);
  resetModalFields();
  closeModal();
};

const handleCancel = () => closeModal();

const handleIncrementChange = (value) => {
  const newIncrement = Number(value);
  const currentIncrement = Number(increment.value);

  // Met à jour l'incrément
  increment.value = newIncrement;

  // Si c'est un vrai changement de valeur (pas juste un blur)
  if (newIncrement !== currentIncrement) {
    // Met à jour le min directement
    min.value = newIncrement;

    // Ajuste le max si nécessaire
    const currentMax = Number(max.value);
    if (currentMax > 0 && currentMax < newIncrement + newIncrement) {
      max.value = newIncrement + newIncrement;
    }
  } else {
    // C'est un blur, on vérifie juste si le min actuel est valide
    const currentMin = Number(min.value);
    if (currentMin < newIncrement || currentMin % newIncrement !== 0) {
      min.value = newIncrement;
    }
  }
};

const handleMinChange = (value) => {
  const newMin = Number(value);
  const currentIncrement = Number(increment.value);
  const currentMax = Number(max.value);

  // Ne met à jour le min que s'il respecte l'incrément
  if (newMin >= currentIncrement) {
    min.value = newMin;

    // Si max existe et n'est pas 0, on l'ajuste pour maintenir min + increment
    if (currentMax > 0 && currentMax < newMin + currentIncrement) {
      max.value = newMin + currentIncrement;
    }
  }
};

const handleMaxChange = (value) => {
  // Permettre max = 0 (pas de limite)
  if (value === 0 || value === '') {
    max.value = 0;
    return;
  }

  const currentIncrement = Number(increment.value);
  const currentMin = Number(min.value);
  let newMax = Number(value);

  // S'assurer que max est supérieur ou égal à min + increment
  if (newMax < currentMin + currentIncrement) {
    newMax = currentMin + currentIncrement;
  }

  // Arrondir au multiple de l'incrément
  const remainder = (newMax - currentMin) % currentIncrement;
  if (remainder !== 0) {
    newMax = newMax - remainder + (remainder > currentIncrement / 2 ? currentIncrement : 0);
  }

  max.value = newMax;
};
</script>

<style lang="scss" scoped>
nav.filters {
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid $gainsboro;
  a {
    text-decoration: none;
    font-size: 90%;
    color: #444444;
    padding: 12px 8px;
    margin-bottom: -1px;
    border-bottom: 3px solid transparent;
  }
  a.active {
    border-bottom: 3px solid $brand-blue;
  }
  a:hover {
    border-bottom: 3px solid $gainsboro;
  }
}
section.search {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

article.product {
  display: grid;
  grid-template-columns: 35px 50px 1fr auto 385px;
  grid-template-rows: 1fr;
  grid-template-areas: 'checkbox image name status actions';
  align-items: center;
  gap: 0 1rem;
  color: $brand-deep-purple;
  padding: 1.35rem 1rem;
  background-color: $white;
  transition: background-color $base-speed-fast ease;
}

article.product.included {
  grid-template-columns: 35px 50px 1fr auto 185px 185px auto;
  grid-template-areas: 'checkbox image name status price rules actions';
}

article.product:nth-of-type(even) {
  border-top: 1px dashed rgba($brand-light-purple, 0.75);
  border-bottom: 1px dashed rgba($brand-light-purple, 0.75);
}
article.product:last-child {
  border-bottom: none;
}
article.product:hover {
  cursor: default;
  background-color: rgba($brand-mint, 0.05);
}

article .status {
  padding: 3px 14px;
  border-radius: 100px;
  font-size: 85%;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  background-color: $brand-light-gold;
  &.green {
    background-color: $brand-light-mint;
  }
}

// Grid Area
.form-checkbox {
  grid-area: checkbox;
  text-align: center;
}
.product {
  grid-area: product;
}
.image {
  grid-area: image;
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: $base-radius;
  overflow: hidden;
  border: 1px solid rgba($brand-blue, 0.1);
  padding: 2px;
}
.name {
  grid-area: name;
  small {
    font-size: 90%;
    color: $brand-blue;
  }
}
.status {
  grid-area: status;
}
.rules {
  grid-area: rules;
  display: flex;
  justify-content: flex-end;
}
.price {
  grid-area: price;
  position: relative;
}
.actions {
  grid-area: actions;
  display: flex;
  justify-content: flex-end;
}
.warning {
  position: absolute;
  font-size: 85%;
  bottom: -20px;
  opacity: 0.75;
}

.form-input {
  padding: 0 0px;
  position: relative;
  label {
    color: rgba($black, 0.65);
  }
}

h6 {
  margin-bottom: 1rem;
}
</style>
