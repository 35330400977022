<template>
  <div class="form-input">
    <label v-if="label" :for="title">{{ $t(title) }}</label>
    <div class="input-wrapper" :class="[{ compact: !!compact }]">
      <input
        :id="title"
        :name="title"
        :value="inputValue"
        :disabled="disabled"
        :placeholder="0"
        type="number"
        @input="handleInput"
        @blur="handleBlur"
        @keydown="handleKeyDown"
      />
      <span v-if="compact && errorMessage" class="error-icon" :msg="errorMessage">⚠️</span>
    </div>
    <p v-if="!compact && errorMessage" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';

const props = defineProps({
  title: { type: String, required: true },
  label: { type: Boolean, default: true },
  modelValue: { type: Number, default: 0 },
  disabled: { type: Boolean, default: false },
  quantity_rules: { type: Object, default: () => ({}) },
  compact: { type: Boolean, default: false }
});

const emit = defineEmits(['update:modelValue', 'input']);
const errorMessage = ref('');
const inputValue = ref(props.modelValue);

// Surveiller les changements de modelValue pour mettre à jour inputValue
watch(
  () => props.modelValue,
  (newValue) => {
    inputValue.value = newValue;
  },
  { immediate: true }
);

const step = computed(() => props.quantity_rules?.increment || 1);
const minValue = computed(() => props.quantity_rules?.min || null);
const maxValue = computed(() => props.quantity_rules?.max || null);

const handleInput = (event) => {
  const value = event.target.value;
  inputValue.value = value;
  emit('update:modelValue', value === '' ? '' : Number(value));
  emit('input', value === '' ? '' : Number(value));
};

const handleBlur = (event) => {
  let value = event.target.value === '' ? '' : Number(event.target.value);

  if (value !== '' && value !== 0) {
    const increment = props.quantity_rules?.increment || 1;

    // Forcer le min
    if (minValue.value !== null && value < minValue.value) {
      value = minValue.value;
      errorMessage.value = `Valeur minimum : ${minValue.value}`;
    }

    // Forcer le max (sauf si 0)
    if (maxValue.value !== null && maxValue.value !== 0 && value > maxValue.value) {
      value = maxValue.value;
      errorMessage.value = `Valeur maximum : ${maxValue.value}`;
    }

    // Forcer l'incrément seulement si > 1 et si la valeur n'est pas 0
    if (increment > 1) {
      const min = minValue.value || 0;
      const remainder = (value - min) % increment;
      if (remainder !== 0) {
        value = value - remainder + (remainder > increment / 2 ? increment : 0);
        errorMessage.value = `Valeur ajustée à l'incrément : ${value}`;
      }
    }
  }

  inputValue.value = value;
  emit('update:modelValue', value);
  emit('input', value);

  if (errorMessage.value) {
    setTimeout(() => {
      errorMessage.value = '';
    }, 3000);
  }
};

const handleKeyDown = (event) => {
  if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
    event.preventDefault();

    let value = Number(event.target.value) || 0;
    const increment = step.value;

    if (event.key === 'ArrowUp') {
      value += increment;
    } else {
      value -= increment;
    }

    // Validation des limites
    if (minValue.value !== null && value < minValue.value) {
      value = minValue.value;
    }
    if (maxValue.value !== null && maxValue.value !== 0 && value > maxValue.value) {
      value = maxValue.value;
    }

    inputValue.value = value;
    emit('update:modelValue', value);
    emit('input', value);
  }
};
</script>

<style scoped lang="scss">
.form-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 0;
  min-width: 90px;
  width: 100%;
  > .input-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
  }

  input {
    width: 100%;
    padding: 10px;
    border-radius: $base-radius;
    border: 1.5px solid;
    border-color: rgba($brand-light-purple, 0.5);
    color: $brand-deep-purple;
    transition: border-color 250ms ease;
    &:focus {
      outline: none;
      border-color: $brand-deep-purple;
    }
    &:disabled {
      background-color: rgba($brand-light-purple, 0.3);
      border-color: transparent;
      color: rgba($brand-deep-purple, 0.7);
      font-style: italic;
    }
  }
  .input-price input {
    padding-right: 26px;
  }
  .currency-symbol {
    position: absolute;
    right: 10px;
    top: 10px;
    color: rgba($brand-deep-purple, 0.4);
    font-size: 95%;
  }
  > label {
    margin-bottom: 6px;
    color: $brand-grey;
    font-size: 85%;
  }
}
.error-message {
  color: darken(red, 20%);
  margin: 0;
  padding: 0.25rem 0.75rem;
  margin-top: 1rem;
  border-radius: $base-radius;
  border: 1px solid darken(red, 10%);
}
.error-icon {
  margin-left: 10px;
  cursor: pointer;
  color: darken(red, 10%);
  &:hover::after {
    content: attr(msg);
    background: rgba(darken(red, 20%), 0.65);
    color: white;
    padding: 5px;
    border-radius: 3px;
    position: absolute;
    bottom: -40px;
    right: 0;
    white-space: nowrap;
    z-index: 1;
    font-size: 85%;
  }
}
</style>
